@charset "utf-8";/* CSS Document */
*{margin: 0;padding: 0;}
html {font-size: 62.5%;height: 100%}
body {margin: 0 auto;font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;height: 100%;overflow-x: hidden;max-width: 1920px;}
img {border: none;}
ul li {list-style-type: none;}
ul, form, p, a, img, table, tr, td, li, dd, dt, dl, span {margin: 0;padding: 0;list-style: none;color: #333;}
a {text-decoration: none;color: #333;outline: none;transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;-webkit-transition: 0.3s;}
h1 {margin: 0;padding: 0;font-weight: normal;color: #333;}
.clear {clear: both;}
.maln {margin-left: auto !important}
input, textarea {font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;color:#333;border: none;outline: none;}
.l{ float:left;}
.r{ float:right;}
.fix{*zoom:1; } .fix:after,.fix:before{display:block; content:"clear"; height:0; clear:both; overflow:hidden; visibility:hidden; }

::-webkit-input-placeholder {/* WebKit browsers */
 color:#999999;}
:-o-placeholder {/* Mozilla Firefox 4 to 18 */
 color:#999999;}
::-moz-placeholder {/* Mozilla Firefox 19+ */
 color:#999999; opacity: 1;}
:-ms-input-placeholder {/* Internet Explorer 10+ */
 color:#999999;}

.search ::-webkit-input-placeholder {/* WebKit browsers */
color:#dbd6d6;}
.search :-o-placeholder {/* Mozilla Firefox 4 to 18 */
color:#dbd6d6;}
.search ::-moz-placeholder {/* Mozilla Firefox 19+ */
color:#dbd6d6; opacity: 1;}
.search :-ms-input-placeholder {/* Internet Explorer 10+ */
color:#dbd6d6;}

.div4 ::-webkit-input-placeholder {/* WebKit browsers */
color:#fff;}
.div4 :-o-placeholder {/* Mozilla Firefox 4 to 18 */
color:#fff;}
.div4 ::-moz-placeholder {/* Mozilla Firefox 19+ */
color:#fff; opacity: 1;}
.div4 :-ms-input-placeholder {/* Internet Explorer 10+ */
color:#fff;}
	


.wp {width: 1200px;margin: auto;}
@media screen and (min-width:1220px) {.wp {width: 1200px;margin: auto;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.wp {width: 980px;margin: auto;}}
@media screen and (max-width:999px) {.wp {width: 95%;margin: auto;}}

.no_margin{margin-left: auto\0!important;}
@media screen and (min-width:1220px) {.no_margin{margin-left: auto!important;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.no_margin{margin-left: auto!important;}}

/*.container*/
.wap-hd-nav { display: none; }

.wap-nav { width: 5px; height: 100%; position: absolute; right: 0; -webkit-transition: all 0.4s ease 0s; -o-transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 999999999999999; cursor: pointer; position: fixed; top: -5px; right: 0; display: block; }

.wap-nav .nav { overflow: hidden; width: 300px; background: rgba(0, 0, 0, 0.8); position: absolute; top: 0; right: 0; display: block; height: auto; overflow: scroll; }

.wap-nav .nav span { font-family: arial, helvetica, sans-serif; }

.wap-nav .nav form { margin: 15px auto; width: 258px; height: 26px; border: 1px solid #fff; }

.wap-nav .nav form .iptc { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; padding-left: 15px; background-color: #fff; width: 80%; height: 26px; line-height: 26px; display: block; float: left; }

.wap-nav .nav form .ipsc { width: 20%; height: 26px; line-height: 26px; display: block; float: right; background: #fff url("../images/sousuo.png") no-repeat center center; }

.wap-nav .nav > ul { display: none; margin-top: 0px; margin-bottom: 0px; overflow: scroll; padding-top: 45px; -ms-box-sizing: border-box; -o-box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.wap-nav .nav > ul > li { line-height: 40px; font-size: 12px; font-size: 1.2rem; text-indent: 20px; position: relative; }

.wap-nav .addition { width: 20px; height: 20px; position: absolute; right: 15px; top: 10px; background: url("../images/shizi.png") no-repeat center center; }

.wap-nav .addition.active { background-image: url("../images/yizi.png"); }

.wap-nav .addition.fa:before { margin-right: 5px; color: #fff; text-indent: 0; }

.wap-nav .nav > ul > li > a { margin-right: 40px; color: #fff; font-size: 12px; font-size: 1.2rem; display: block; }

.wap-nav .nav > ul > li > a span { color: #fff; font-size: 12px; font-size: 1.2rem; }

.wap-nav .nav > ul > li .c-show { display: none; width: 100%; }

.wap-nav .nav > ul > li .c-show1 .c-s1 { position: relative; padding-left: 15px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > a { position: relative; margin-right: 40px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a { margin-right: 40px; text-indent: 35px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a { margin-right: 40px; text-indent: 50px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > dl { padding: 20px; }

.wap-nav .nav > ul > dl dd { float: left; margin: 0 2px; }

.wap-nav .menubtn { width: 23px; height: 18px; position: absolute; top: 12px; right: 12px; -webkit-transition: all 0.4s ease 0s; -o-transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 9999; }

.wap-nav .menubtn span { display: block; width: 100%; height: 3px; margin-bottom: 3px; background-color: #368239; }

@media screen and (min-width: 768px) { .wap-nav, .menubtn { display: none; } }

@media screen and (max-width: 991px) { .wap-hd-nav { display: -webkit-box; display: -webkit-flex; display: -moz-box; display: -ms-flexbox; display: flex; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -webkit-flex-flow: row nowrap; -moz-box-orient: horizontal; -moz-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; -webkit-box-pack: justify; -webkit-justify-content: space-between; -moz-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; background-color: #130171; }
  .wap-hd-nav-item { width: 25%; }
  .wap-hd-nav-item a { display: block; padding: 5px 0; line-height: 20px; font-size: 14px; color: white; text-align: center; background-color: #363636; }
  .wap-hd-nav-item a.active { color: white; background-color: #368239; } }


/***********  lunbo  *************/
*{margin: 0;padding:0;}
li{list-style: none;}

.slide { position: relative;}

.slide .carouse { width: 100%; overflow: hidden; position: relative; }

.slide .carouse .slideItem { width: 100%; position: absolute; cursor: pointer; }

.slide .carouse .slideItem .banner-img { width: 100%;display: block; }

.slide .console { height: 57px; display: inline-block; width: 35px; position: absolute; top: 40%; }

.slide .dotList { position: absolute; width: 100%; bottom: 11px; text-align: center; display: none;}

.slide .dotList ul li { display: inline-block; width: 14px; height: 14px; background:#fff; margin: 0 8px; cursor: pointer; }

.slide .carousel-control { position: absolute; top: 50%; width: 56px; height: 56px; line-height: 56px;text-align: center;border: 3px solid #fff;border-radius: 50%;z-index: 999; margin-top: -31px;}

.slide .carousel-control:hover{border: 3px solid #4caf50;}

.slide .carousel-control i{color: #fff;font-size: 30px;font-size: 3rem;}

.slide .carousel-control:hover i{color: #4caf50;}

.slide .carousel-control.left { left: 8%;cursor: pointer; }

.slide .carousel-control.right { right: 8%;cursor: pointer; }

.slide .dotList ul li{cursor: pointer; background:#fff;  font-size: 0px;   margin-left: 3px;margin-right: 3px; border: none;outline: none;width: 14px;height:14px;border-radius: 50%;}
.slide .dotList ul li.active{background:#4caf50;}

.slide .slidedetail { display: none; }

@media screen and (max-width:999px) {
	.slide .carousel-control{display: none;}
	.slide .dotList{display: block;}
}
@media screen and (max-width:767px) {
    .slide .dotList{display: block;bottom: 5px;}
}

/***********  toplx  ************/
.headbox{position: absolute;left: 0;top: 0;width: 100%;z-index: 9999;}

.m-hdlang { position: absolute; overflow: visible;right: 0;top: 10px;padding-right: 20px;background: url(../images/xiala.png) no-repeat right 9px;}

.m-hdlang .lang-list { position: absolute; left: 0; top: 100%; background-color: #FFF; z-index: 999; width: 100%; display: none; text-align: center; font-size: 12px; font-size: 1.2rem; border: solid 1px #C5C5C5; margin-top: -2px; padding-top: 3px; padding-bottom: 1px; }

.m-hdlang .lang-item { float: none; line-height: 22px; }

.m-hdlang .lang-item a { color: #555; }

.m-hdlang .lang-item a:hover { color: #4caf50; }

.m-hdlang > .lang-item { font-size: 14px; font-size: 1.4rem; display: inline-block; color: #fff; padding-left: 38px; background: url(../images/topdt.png) no-repeat left center; line-height: 24px; }

.m-hdlang > .lang-item i { font-size: 12px; font-size: 1.2rem; color: #fff; vertical-align: middle; margin-left: 7px; }


/***********  head  ************/
.head{border-bottom: 1px solid #fff;border-bottom: 1px solid rgba(255,255,255,0.5);}
.head .wp{position: relative;}
.logo img{max-width: 100%;display: block;}
.navlist{padding-top: 43px;}
.navlist2{display: none;}
.navlist>li{float: left;margin-left: 10px;position: relative;padding-bottom: 19px;}
.navlist>li>a{font-size: 16px;font-size: 1.6rem;display: block;padding: 0 20px;line-height: 36px;border-radius: 18px;color: #fff;}
.navlist>li:hover>a{background: #4caf50;}
.navlist>li.currentnav>a{background: #4caf50;}

@media screen and (max-width:1219px) {
	.navlist{width: 100%;padding-top: 5px;}
	.navlist > li{width: 14.2857%;text-align: center;margin: 0;}
	.navlist > li{padding-bottom: 12px;}
	.navlist > li > a{padding: 0;}
	.logo{float: none;display: block;max-width: 200px;margin: 0 auto;}
}
@media screen and (max-width:999px) {
	.headbox{position: static;background: #4caf50;}
	.navlist>li>a{border-bottom: 1px solid transparent;border-radius: 0;}
	.navlist>li:hover>a{border-bottom: 1px solid #fff;}
	.navlist>li.currentnav>a{border-bottom: 1px solid #fff;}
}
@media screen and (max-width:767px) {
	.navlist{display: none;}
	.m-hdlang{position: static;display: inline-block;}
	.head{text-align: center;padding-bottom: 10px;}
}

/**************  sya  *************/
.sya{padding-top: 70px;padding-bottom: 137px;}

.sybt{text-align: center;font-size: 72px;font-size: 7.2rem;font-family: "AMAZB";}
.sybt a{color: #4caf50;}
.sydes{display: block;text-align: center;font-size:24px;font-size:2.4rem;color: #1f1e19;line-height: 30px;max-width: 1007px;margin: 0 auto;margin-top: 15px;}
.sydes:hover{color: #4caf50;}

.amain{margin-top: 100px;text-align: center;position: relative;padding-top: 76px;padding-bottom: 162px;}
.apart{position: absolute;width: 330px;}
.apart1{left: -85px;top: 0;}
.apart2{right: -85px;top: 0;}
.apart3{left: -85px;bottom: 0;}
.apart4{right: -85px;bottom: 0;}

.syap1{display: block;font-size:24px;font-size:2.4rem;color: #4caf50;line-height: 30px;margin-top: 15px;margin-bottom: 7px;}
.syap2{display: block;font-size:16px;font-size:1.6rem;color: #1f1e19;line-height: 28px;}
.syap2:hover{color: #4caf50;}

@media screen and (max-width:1420px) {
	.apart1{left: 0;}
	.apart2{right: 0;}
	.apart3{left: 0;}
	.apart4{right: 0;}
	.atu img{width: 550px;}
	.amain{padding-top: 120px;}
}
@media screen and (max-width:1219px) {
	.amain{margin-top: 60px;padding-bottom: 0;}
	.sybt{font-size: 60px;font-size: 6rem;}
	.sydes{font-size:20px;font-size:2rem;}
	.atu img{width: 470px;}
	.apart{width: 250px;}
	.ap1{font-size:20px;font-size:2rem;}
	.sya{padding-top: 50px;padding-bottom: 90px;}
}
@media screen and (max-width:999px) {
	.atu img{width: 400px;}
	.sybt{font-size: 50px;font-size: 5rem;}
	.sydes{font-size:16px;font-size:1.6rem;}
	.amain{padding-top: 0;font-size: 0;letter-spacing: 0;}
	.apart{position: static;display: inline-block;vertical-align: top;width: 48%;margin: 0 1%;margin-bottom: 20px;}
	.atu{display: block;margin-bottom: 20px;}
	.atu img{width: 70%;}
	.sya{padding-bottom: 60px;}
}
@media screen and (max-width:767px) {
	.sya{padding-top: 35px;}
	.sybt{font-size: 30px;font-size: 3rem;}
	.amain{margin-top: 40px;}
}
@media screen and (max-width:550px) {
	.ap1{font-size:16px;font-size:1.6rem;}
	.ap2{font-size:14px;font-size:1.4rem;}
	.sydes{font-size:14px;font-size:1.4rem;}
	.sya{padding-bottom: 45px;}
}
@media screen and (max-width:400px) {
	.apart{width: 98%;}
	.apart img{width: 100px;}
	.sya{padding-bottom: 30px;}
}

/*************  sysp  ************/
.sysp img{display: block;width: 100%;max-width: 1920px;cursor: pointer;}
.sp_cover{position: fixed;left: 0;top: 0;right: 0;bottom: 0;background: #000;background: rgba(0,0,0,0.5);z-index: 9999999;display: none;}
.sp_cover i{font-size:20px;font-size:2rem;width: 28px;height: 28px;border: 1px solid #ddd;color: #ddd;border-radius: 50%;text-align: center;line-height: 28px;position: absolute;right: 30px;top: 30px;cursor: pointer;display: block;}
.vitu{position: relative;height: 100%;width: 100%;overflow: hidden;}
.vitu video {  position: absolute; top:0; left: 0; width: 100%; height: 100%; border:none;}
.vitu video body{ margin:0px !important;}

.sybox{width: 90%;margin: 0 auto;margin-top: 10%;}

/************  syp  ************/
.syp{padding-top: 64px;padding-bottom: 83px;text-align: center;}
.syp .sydes{margin-bottom: 97px;}
.sypbox{width: 390px;float: left;margin-left: 15px;margin-bottom: 31px;text-align: center;}
.sypcon{border: 15px solid #f1f1f1;background: #f1f1f1;transition: 0.5s;}
.syptu{display: block;overflow: hidden;}
.syptu img{display: block;width: 100%;max-width: 425px;max-height: 425px;transition: 0.5s;}
.syptu:hover img{transform: scale(1.1);}
.sypp1{display: block;line-height: 82px;font-size:24px;font-size:2.4rem;color: #1f1e19;transition: 0.5s;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.sypbox:hover .sypcon{border: 15px solid #4caf50;background: #4caf50;}
.sypbox:hover .sypp1{color: #fff;}
.sypmore{display: inline-block;min-width: 177px;height: 50px;line-height: 50px;border-radius: 25px;border: 1px solid #dbd6d6;box-sizing: border-box;padding: 0 10px;font-size:18px;font-size:1.8rem;margin-top: 17px;}

@media screen and (max-width:1219px) {
	.sypbox{width: 316px;}
	.syp .sydes{margin-bottom: 75px;}
}
@media screen and (max-width:999px) {
	.sypbox{width: 31.333%;margin: 0 1%;margin-bottom: 20px;}
	.syp{padding-top: 50px;}
	.syp .sydes{margin-bottom: 55px;}
	.sypp1{font-size:18px;font-size:1.8rem;line-height: 50px;}
	.sypcon{border: 8px solid #f1f1f1;}
	.sypbox:hover .sypcon{border: 8px solid #4caf50;}
	.sypmore{font-size:16px;font-size:1.6rem;}
}
@media screen and (max-width:767px) {
	.sypp1{font-size:16px;font-size:1.6rem;}
	.syp .sydes{margin-bottom: 35px;}
}
@media screen and (max-width:600px) {
	.sypbox{width: 48%;}
	.syp{padding-top: 35px;}
}

/**************  syapp  **************/
.syappbox{width: 49.427%;position: relative;overflow: hidden;text-align: center;}
.syapptu img{display: block;width: 100%;max-width: 949px;max-height: 600px;}
.syappcover{position: absolute;left: 0;top: -100%;width: 100%;height: 100%;background: #000;background: rgba(0,0,0,0.4);transition: 0.5s;}
.syappcon{position: absolute;left: 54px;top: 68px;right: 54px;bottom: 68px;border: 3px solid #fff;border: 3px solid rgba(255,255,255,0.5);padding: 0 20px;}
.syapp1{display: block;font-size: 30px;font-size: 3rem;color: #fff;margin-top: 16%;margin-bottom: 22px;}
.syapp2{display: block;font-size:24px;font-size:2.4rem;line-height: 30px;height: 60px;overflow: hidden;color: #fff;max-width: 470px;margin: 0 auto;margin-bottom: 35px;}
.syapp3 i{color: #fff;font-size: 60px;font-size: 6rem;}
.syappbox:hover .syappcover{top: 0;}

@media screen and (max-width:1500px) {
	.syapp1{margin-top: 10%;}
	.syapp1{font-size:24px;font-size:2.4rem;}
	.syapp2{font-size:20px;font-size:2rem;margin-bottom: 20px;}
}
@media screen and (max-width:1300px) {
	.syapp3 i{font-size: 40px;font-size: 4rem;}
}
@media screen and (max-width:1200px) {
	.syappcon{top: 45px;bottom: 45px;}
	.syapp1{font-size:20px;font-size:2rem;}
	.syapp2{font-size:16px;font-size:1.6rem;}
}
@media screen and (max-width:999px) {
	.syappcover{position: static;background: #4caf50;padding-top: 1px;padding-bottom: 15px;}
	.syappcon{position: static;border: none;}
	.syapp1{margin-top: 15px;margin-bottom: 12px;}
	.syapp2{margin-bottom: 0;}
	.syapp3{display: none;}
	.syp{padding-bottom: 60px;}
}
@media screen and (max-width:767px) {
	.syp{padding-bottom: 50px;}
	.syapp1{font-size:16px;font-size:1.6rem;}
	.syapp2{font-size:14px;font-size:1.4rem;}
	.sypmore{font-size:14px;font-size:1.4rem;height: 40px;line-height: 40px;min-width: 160px;}
	.syappcon{padding: 0 10px;}
}
@media screen and (max-width:420px) {
	.syappbox{width: 100%;margin-bottom: 10px;}
}
/**************  syn  **************/
.syn{padding-top: 71px;padding-bottom: 181px;}
.syn .sydes{margin-bottom: 95px;}
.syntu{display: block;overflow: hidden;}
.syntu img{display: block;width: 100%;max-width: 397px;max-height: 300px;transition: 0.5s;}
.syntu:hover img{transform: scale(1.1);}
.synbox{width: 33.333%;text-align: center;float: left;}
.syncon{position: relative;}
.synbox1 .syncon>img{position: absolute;left: 50%;margin-left: -24px;bottom: 0;}
.synbox2 .syncon>img{position: absolute;left: 50%;margin-left: -24px;top: 0;}
.synxia{background: #f1f1f1;padding: 0 70px;padding-top: 66px;padding-bottom: 60px;}
.synp1{display: block;font-size:18px;font-size:1.8rem;font-weight: bold;text-transform: uppercase;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.synp2{display: block;font-size:16px;font-size:1.6rem;color: #757575;line-height: 20px;height: 60px;overflow: hidden;margin-top: 20px;margin-bottom: 39px;}
.synp3{display: inline-block;min-width: 100px;height: 36px;line-height: 36px;box-sizing: border-box;padding: 0 10px;background: #4caf50;columns: #fff;text-transform: uppercase;color: #fff;}
.synp1:hover{color: #4caf50;}
.synp2:hover{color: #4caf50;}

@media screen and (max-width:1219px) {
	.synxia{padding: 0 20px;padding-top: 40px;padding-bottom: 32px;}
	.syn .sydes{margin-bottom: 60px;}
	.syn{padding-bottom: 150px;}
}
@media screen and (max-width:999px) {
	.syn .sydes{margin-bottom: 40px;}
	.syn{padding-top: 50px;padding-bottom: 85px;}
}
@media screen and (max-width:767px) {
	.synbox{width: 50%;}
	.syn{padding-bottom: 60px;padding-top: 40px;}
	.synp2{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:600px) {
	.synxia{padding-top: 25px;padding-bottom: 25px;}
	.synp2{margin-top: 15px;margin-bottom: 20px;}
}
@media screen and (max-width:450px) {
	.synbox{width: auto;float: none;max-width: 397px;margin: 0 auto;margin-bottom: 20px;}
	.syn{padding-bottom: 30px;}
}


/********  sylx  *******/
.sylx{position: absolute;top: -48px;left: 50%;margin-left: -486px;}

.search{width: 973px;background: #4caf50;margin: 0 auto;padding-top: 33px;padding-bottom: 32px;}
.search form{margin-left: 70px;margin-right: 70px;position: relative;}
.sou{width: 800px;height: 36px;line-height: 36px;padding-left: 33px;border-radius: 18px;font-size:16px;font-size:1.6rem;}
.suo{position: absolute;right: 1px;top: 1px;width: 66px;height: 36px;background: transparent;cursor: pointer;}
.search form i{position: absolute;right: 1px;top: 1px;width: 66px;height: 36px;line-height: 36px;text-align: center;font-size: 23px;font-size: 2.3rem;color: #999999;}

/*************  bottom  ************/
.bottom{background: #1f1e19;position: relative;padding-top: 87px;}
.bottom p,.bottom li,.bottom a,.bottom span{color: #fff;}
.bottom a:hover{color: #4caf50;}
.div>a,.div>span{display: block;font-size:20px;font-size:2rem;color: #fff;margin-bottom: 40px;}

.div1{margin-right: 120px;width: 270px;padding-top: 23px;}
.div1>a{margin-bottom: 10px;}

.div2{margin-right: 90px;}
.div2 li{line-height: 30px;}

.div3{width: 220px;}
.div3 li{line-height: 34px;}
.bsjj a{margin-right: 17px;position: relative;transition: 0.5s;top: 0;}
.bsjj{padding-top: 22px;}
.bsjj a:hover{top: -10px;}
.bsjj i{font-size:20px;font-size:2rem;}
.copyright,.support{line-height: 24px;}
.cnzz{padding-left: 10px;}

.div4{width: 276px;}

.div4>a{font-size:24px;font-size:2.4rem;}

.div4 .xing { margin-bottom: 12px; }

.div4 .intbox3.xing { margin-bottom: 11px; }

.div4 .xing input { height: 28px; line-height: 28px; color: #fff; padding-left: 3px; border-bottom: 1px solid #8f8f8c; background: transparent;font-size:16px;font-size:1.6rem;}

.div4 .intbox1 input { width: 272px; }

.div4 .intbox2 input { width: 272px; }

.div4 .intbox3 textarea { width: 272px; padding-left: 3px; line-height: 28px; height: 28px; color: #fff; border-bottom: 1px solid #8f8f8c; background: transparent;}

.div4 .intbox4 input.syint1 { width: 179px; float: left; margin-top: 7px; }

.div4 .intbox4 input.syint2 { padding: 0; width: 126px; height: 32px; line-height: 32px; text-align: center; background: #4caf50; color: #fff;margin: 0 auto;margin-top: 18px;cursor: pointer; text-transform: uppercase; border: none; border-radius: 5px;display: block;}

.div4 .intbox4 img {cursor: pointer; float: right;}

.div4 .intbox4 input.syint2:hover { background: #429e46; }

.link{padding-top: 10px;padding-bottom: 10px;line-height: 24px;min-height: 24px;background: #272621;margin-top: 77px;}
.link a{padding: 0 20px;}
.link a,.link span{display: inline-block;}
.link a:hover{color: #4caf50;}

@media screen and (min-width:1000px) and (max-width:1219px) {
	.div1{margin-right: 40px;}
	.div2{margin-right: 50px;}
	.search{width: 860px;}
	.sylx{margin-left: -430px;}
	.sou{width: 687px;}
}
@media screen and (max-width:999px) {
	.sylx{position: static;margin-left: 0;margin-bottom: 30px;}
	.search{width: 100%;}
	.search form{margin: 0 30px;}
	.div1,.div2{margin-bottom: 20px;}
	.div1,.div2,.div3{width: 48%;margin-right: 2%;}
	.div4{float: left;}
	.bottom{padding-top: 25px;}
	.link{margin-top: 25px;}
	.sou{width: calc(100% - 33px);}
}
@media screen and (max-width:767px) {
    .div2,.div3,.div4{display: none;}
    .div1{width: 100%;margin-bottom: 0;margin-right: 0;padding-top: 0;}
    .bottom{padding-top: 20px;padding-bottom: 60px;}
	.link{display: none;}
	.search{padding-top: 15px;padding-bottom: 15px;}
}
@media screen and (max-width:450px) {
	.search form{margin: 0 10px;}
	.bsjj{padding-top: 12px;}
}

/************  bread  *************/
.mianbao{height: 350px;background: url(../images/bannerf.jpg) no-repeat center top;}
.dabt{ font-size:72px; font-size:7.2rem;color:#fff;font-family: "AMAZB";text-align: center;padding-top: 142px;}
.mianbao .wp{position: relative;height: 350px;}
.bread{color:#999999;position: absolute;left: 0;bottom: 0;width: 100%;box-sizing: border-box;background: #ecf0e6;padding: 0 11px;line-height: 26px;padding-top: 7px;padding-bottom: 7px;}
.bread a{ color:#999999;}
.bread a:hover{ color:#4caf50;}
.co00{ color:#4caf50 !important}

.fymain{padding-top: 36px;padding-bottom: 153px;}

/**********   about   **********/
.ap1{font-size:18px;font-size:1.8rem;line-height: 54px;margin-bottom: 40px;}
.atu1 img{display: block;width: 100%;max-width: 1200px;}
.atu1{margin-bottom: 43px;}
.aditu{margin-top: 57px;}
.aditu img{display: block;width: 100%;max-width: 1920px;}
.aform{padding-top: 75px;}

.ap2{text-align: center;font-size: 62px;font-size: 6.2rem;color: #4caf50;font-family: "AMAZB";}

.ap3{text-align: center;font-size:24px;font-size:2.4rem;color: #999999;font-family: Verdana, Geneva, Tahoma, sans-serif;margin-top: 12px;}

.aform form{width: 1075px;margin: 0 auto;margin-top: 38px;}

.fxp_div1 { margin-bottom: 10px; }

.fxp_div2 { margin-bottom: 10px; }

.fxp_nr { position: relative; margin-bottom: 28px; }

.fxp_bt { float: left; width: 22px; height: 42px; line-height: 42px; font-family: Verdana, Geneva, sans-serif; color: #ff0000; font-size: 16px; font-size: 1.6rem; }

.fxp_input, .fxp_textarea {font-size:16px;font-size:1.6rem; color: #999999; border-bottom: 1px solid #d3d3d3;}

.fxp_input, .fxp_captcha { height: 42px; line-height: 42px; float: left; border-bottom: 1px solid #d3d3d3;}

.fxp_input { width: 1053px; }

.fxp_textarea { width: 1053px; line-height: 42px;height: 42px; }

.fxp_captcha {background: #fff; font-size: 14px; font-size: 1.4rem; color: #666666; width: 900px; }

.fxp_yzm { cursor: pointer; float: right; }

.fxp_send {cursor: pointer; text-align: center; font-size:16px;font-size:1.6rem; color: #fff; background: #4caf50; width: 440px; height: 34px; line-height: 34px; text-transform: uppercase; margin: 0 auto;border-radius: 5px;margin-top: 47px;display: block;}

.fxp_send:hover { background: #3b913e; transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; -webkit-transition: 0.5s; color: #fff; }


@media screen and (max-width:1219px) {
	.aform form{width: 100%;}
	.fxp_input,.fxp_textarea{width: 958px;}
	.fxp_captcha{width: 830px;}
	.dabt{font-size: 60px;font-size: 6rem;}
}
@media screen and (max-width:999px) {
	.ap1{line-height: 42px;margin-bottom: 25px;}
	.aform{padding-top: 50px;}
	.ap2{font-size: 50px;font-size: 5rem;}
	.ap3{font-size:20px;font-size:2rem;}
	.fxp_input, .fxp_textarea{width: calc(100% - 22px);}
	.fxp_captcha{width: calc(100% - 140px);}
	.fymain{padding-bottom: 50px;padding-top: 30px;}
	.mianbao{height: auto;}
	.mianbao .wp{height: auto;padding-bottom: 90px;}
	.dabt{padding-top: 50px;font-size: 50px;font-size: 5rem;}
}
@media screen and (max-width:767px) {
	.ap1{font-size:16px;font-size:1.6rem;line-height: 32px;}
	.atu1{margin-bottom: 25px;}
	.aditu{margin-top: 35px;}
	.ap2{font-size: 40px;font-size: 3rem;}
	.ap3{font-size:14px;font-size:1.4rem;}
	.aform{padding-top: 35px;}
	.fxp_send{width: 100%;}
	.dabt{font-size: 40px;font-size: 4rem;padding-top: 35px;}
	.mianbao .wp{padding-bottom: 70px;}
}
@media screen and (max-width:450px) {
	.fxp_captcha{width: calc(100% - 22px);}
	.fxp_yzm{float: left;margin-top: 20px;}
	.fxp_send{margin-top: 20px;}
	.fymain{padding-bottom: 35px;}
	.dabt{font-size: 32px;font-size: 3.2rem;}
}

/**************  product1  ***************/
.pzuo { width: 287px; }

.pyou { width: 896px; }

.subNavBox .subNav { cursor: pointer; line-height: 38px; padding-left: 42px; padding-right: 35px; background: #f1f1f1 url(../images/prltjia.png) no-repeat right center; margin-bottom: 3px; padding-top: 10px; padding-bottom: 10px; }

.subNavBox .subNav a { font-size:16px;font-size:1.6rem; color: #333333; display: block; }

.subNavBox .subNav:hover { background: #4caf50 url(../images/prltjiah.png) no-repeat right center; }

.subNavBox .subNav:hover a { color: #fff; }

.subNavBox .currentDd a { color: #fff; }

.subNavBox .currentDt { background: #4caf50 url(../images/prltjiah.png) no-repeat right center; }

.subNavBox .navContent { display: none; background: #f7f7f7; margin-top: -3px; padding-top: 14px; padding-bottom: 21px;margin-bottom: 3px;}

.subNavBox .navContent > li>a { display: block; line-height: 24px; padding: 5px 0; color: #333333; padding-left: 84px; background: url(../images/prltjian.png) no-repeat left 8px; }

.subNavBox .navContent > li>a:hover { color: #4caf50; background: url(../images/prltjianh.png) no-repeat left 13px; }

.subNavBox .navContent > li div{padding-left: 92px;margin-bottom: 13px;}
.subNavBox .navContent > li div p{line-height: 30px;}

.title1 {line-height: 38px;padding-top: 10px;padding-bottom: 10px;text-align: center;background: #4caf50;color: #fff;font-size:16px;font-size:1.6rem;text-transform: uppercase;margin-top: 19px;margin-bottom: 13px;}
.title1 a{color: #fff;}
.tags a{display: inline-block;padding: 0 16px;line-height: 27px;border: 1px solid #4caf50;color: #4caf50;margin-bottom: 11px;margin-right: 10px;}
.tags a:hover{background: #4caf50;color: #fff;}

.pbox{width: 291px;float: left;margin-left: 11px;text-align: center;margin-bottom: 23px;}
.pcon{border: 11px solid #f1f1f1;background: #f1f1f1;}
.pconn{position: relative;overflow: hidden;}
.pcover{position: absolute;left: 0;top: -100%;width: 100%;height: 100%;}
.pcover a{min-width: 102px;height: 32px;line-height: 32px;border-radius: 16px;display: inline-block;background: #4caf50;background: rgba(76,175,80,0.9);color: #fff;position: relative;top: 50%;margin-top: -16px;}

.ptu{display: block;}
.ptu img{display: block;width: 100%;max-width: 425px;max-height: 425px;}
.pp1{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;line-height: 62px;}
.pp1 input{cursor: pointer;margin-right: 14px;}
.pp1 a{font-size:16px;font-size:1.6rem;}
.pp1 a:hover{color: #4caf50;}
.pbox:hover .pcover{top: 0;}

.ms_ye{text-align: center;position: relative;margin-top: 22px;}
.ms_ye a{display: inline-block;width: 25px;line-height: 25px;text-align: center;margin-left:3px;margin-right:3px;font-size: 12px;font-size: 1.2rem;background: #fff;border: 1px solid #161616;border-radius: 50%;color: #0a0a0a;}
.ms_sx{width: auto!important;background: none!important;border: none!important;margin:0 8px!important;color: #333!important;}
.ms_sx:hover{color: #4caf50!important;}
.ms_ye .ms_current_ye{background: #4caf50; color: #fff;border: 1px solid #4caf50;}
.ms_ye a:hover{background: #4caf50; color: #fff;border: 1px solid #4caf50;}

.xiangshang { position: absolute;right: 0;bottom: 0; padding-left: 16px; background: url(../images/xs.jpg) no-repeat left 10px; margin-left: 15px; }

.xiangshang span{font-size:16px;font-size:1.6rem;color: #4b4b4b;margin-right: 2px;}

.xiangshang input { min-width: 170px; height: 32px; line-height: 32px; box-sizing: border-box;padding: 0 10px;text-align: center; color: #fff; cursor: pointer; background: #4caf50;}

.xiangshang input:hover { background: #333; }

.product1 .ms_ye{text-align: left;}

@media screen and (max-width: 1219px) { .pyou { width: 670px; }
.pbox{width: 216px;}
}

@media screen and (max-width: 999px) { .pyou { width: 100%; }
  .pzuo { width: 100%; margin-top: 50px; }
  .ms_ye a { margin-bottom: 10px; }
  .pbox{width: 31.333%;margin: 0 1%;margin-bottom: 20px;}
  .pcover{position: static;}
  .pcover a{position: static;margin-top: 20px;}
  .xiangshang{position: relative;display: inline-block;}
  .product1 .ms_ye{text-align: center;}
}
@media screen and (max-width: 550px) {
	.pbox{width: 48%;}
	.pp1 input{margin-right: 8px;}
	.pp1{line-height: 46px;}
	.pcover a{margin-top: 15px;}
	.subNavBox .subNav{line-height: 30px;}
	.title1{line-height: 26px;}
}
@media screen and (max-width: 400px) {
	.pp1 a{font-size:14px;font-size:1.4rem;}
	.pbox{margin-bottom: 12px;}
}

/************  product2  *************/
/*-------------products2 start-------------------------------------------------------------*/
.m-propic { margin: 0 auto; width: 561px; }

.m-propic .pic-big { position: relative; float: left; width: 427px; }

.m-propic .big-img { display: block; line-height: 0; font-size: 0; border-radius: 0; border: 1px solid #ddd; }

.m-propic .big-img .zoomPad { float: none; z-index: 8; }

.m-propic .big-img img { border-radius: 0;  max-width: 100%;}

.m-propic .zoomWrapperImage img { max-width: 1200px; }

.m-propic .pic-small { position: relative; float: right; width: 126px; padding-top: 13px; padding-bottom: 13px; overflow: hidden; }

.m-propic .pic-btn { position: absolute; margin: 0 auto; left: 50%;margin-left: -18px;display: block; width: 36px; height: 14px;line-height: 14px;background: #94cf96;cursor: pointer; text-align: center; }

.m-propic .pic-btn .iconfont { font-size: 12px; font-size: 1.2rem; color: #fff; }

.m-propic .pic-btn:hover { background: #4caf50; }

.m-propic .pic-btn.prev { top: 0; }

.m-propic .pic-btn.next { bottom: 0; }

.m-propic .pic-list { height: 399px;overflow: hidden; }

.m-propic .pic-list ul { height: 2000px; }

.m-propic .pic-list .list-item { margin-bottom: 10px; overflow: visible; padding-left: 0; position: relative; }

.m-propic .pic-list .list-item .pxjiao { position: absolute; left: -3px; top: 50%; margin-top: -4px; display: none; }

.m-propic .pic-list .item-pics { display: block; border-radius: 0; border: 1px solid #ddd; position: relative; }

.m-propic .pic-list .item-pics img { width: 100%; display: block; }

.m-propic .item-pics:hover, .m-propic .item-pics.zoomThumbActive { border-color: #5db161; }

.m-propic .item-pics:hover .pxjiao, .m-propic .item-pics.zoomThumbActive .pxjiao { display: block; }

.m-propic2 { display: none; }

@media screen and (max-width: 1219px) { .zoomPad { cursor: default !important; }
  .zoomPup { display: none !important; }
  .zoomWindow { display: none !important; }
  .zoomPreload { display: none !important; }
  .m-propic { max-width: 100%; float: none; }
  .m-propic .pic-big { height: auto; }
  /*.m-propic .pic-small { width: 110px; } .m-propic .pic-list { height: 444px; } .m-propic .list-item { margin-bottom: 40px; } */ }

@media screen and (max-width: 767px) { .m-propic1 { display: none; }
  .m-propic2 { position: relative; display: block; overflow: hidden; width: auto; padding-left: 30px; padding-right: 30px; }
  .m-propic2 .box-btn { position: absolute; top: 50%; margin-top: -12px; display: block; width: 24px; height: 24px; }
  .m-propic2 .box-btn .iconfont { font-size: 24px; font-size: 2.4rem; color: #888; }
  .m-propic2 .box-btn .iconfont:hover { color: #5db161; }
  .m-propic2 .box-btn.prev { left: 0; }
  .m-propic2 .box-btn.next { right: 0; }
  .m-propic2 .box-list { overflow: hidden; }
  .m-propic2 .box-list ul { width: 1000%; }
  .m-propic2 .list-item { float: left; margin-left: .1%; margin-right: .1%; margin-bottom: 0; width: 3.13%; border-radius: 0; border: 0 solid #d7d7d7; border: 0 solid rgba(113, 113, 113, 0.3); overflow: hidden; }
  .m-propic2 .list-item img { display: block; max-width: 100%; } }

@media only screen and (max-width: 500px) { .m-propic2 .list-item { float: left; margin-left: .1%; margin-right: .1%; margin-bottom: 0; width: 4.8%; } }

@media only screen and (max-width: 480px) { .m-propic2 .list-item { float: left; margin-left: .1%; margin-right: .1%; margin-bottom: 0; width: 9.8%; } }

.item-btns { margin-top: 4px; }

.pro2-info .item-title { line-height: 26px; font-size: 18px; font-size: 1.8rem; font-weight: bold;color: #212121;}

.pro2-info .pro-share { float: left; margin-top: 8px; }

.pro2-info .item-btn { display: inline-block; width: 100%; height: 58px; line-height: 58px; background: #5db161; text-align: center; font-size: 20px; font-size: 2rem; color: #555555; }

.pro2-info .item-btn:hover { background-color: #5db161; color: #fff; border-color: #5db161; }

.pro2-info .item-btn span { color: #fff; display: inline-block; padding-right: 50px; background: url(../images/xpjt.png) no-repeat right center; }

@media only screen and (max-width: 999px) { .pro2-info { text-align: center; }
  .pro2-info .item-title { padding-right: 0; }
  .pro2-info .pro-share { float: none; }
  .pro2-info .item-btns { float: none; margin-top: 20px; } }

@media only screen and (max-width: 767px) { .pro2-info .item-title { line-height: 30px; font-size: 20px; font-size: 2rem; margin: 0px 0 10px; }
  .pro2-info .item-btn { height: 32px; line-height: 30px; font-size: 14px; font-size: 1.4rem; } }

@media only screen and (max-width: 400px) { .pro2-info .item-btn { width: 45%; }
  .pro2-info .item-btn:first-child { margin-right: 5%; } }

.pro2-title { font-size: 24px; font-size: 2.4rem; border-bottom: solid 1px #BFBFBF; position: relative; padding-bottom: 15px; text-transform: capitalize; }

.pro2-title, .pro2-title a { color: #5db161; }

.pro2-title:after { position: absolute; content: ''; width: 129px; height: 3px; background-color: #5db161; left: 0; bottom: -2px; }

@media screen and (max-width: 767px) { .pro2-title { font-size: 20px; font-size: 2rem; padding-bottom: 10px; } }

.pro2-descbox { margin-top: 64px; }

@media screen and (max-width: 999px) { .pro2-descbox { margin-top: 50px; } }

@media screen and (max-width: 767px) { .pro2-descbox { margin-top: 30px; } }

.pro2-spec { padding: 24px 0 52px; font-size: 16px; font-size: 1.6rem; line-height: 30px; }

.pro2-spec, .pro2-spec p, .pro2-spec li, .pro2-spec a, .pro2-spec span, .pro2-spec b, .pro2-spec strong, .pro2-spec dd, .pro2-spec em { color: #555; }

.pro2-spec img, .pro2-spec iframe { max-width: 100%; }

.pro2-spec table { border: 0; }

.pro2-spec table tr:nth-child(odd) { background-color: #f3f3f3; }

.pro2-spec table tr:first-child td { padding-top: 10px; }

.pro2-spec table tr:last-child td { padding-bottom: 8px; }

.pro2-spec table tr td { border: solid 2px #f3f3f3; line-height: 28px; padding: 0 24px; font-size: 16px; font-size: 1.6rem; font-family: Verdana; }

.pro2-spec table tr td:first-of-type { border-left: 0; }

.pro2-spec table tr td:last-of-type { border-right: 0; }

@media screen and (max-width: 767px) { .pro2-spec { padding: 24px 0 30px; font-size: 14px; font-size: 1.4rem; line-height: 24px; }
  .pro2-spec table tr td { font-size: 14px; font-size: 1.4rem; } }

/* pro2-parabox start */
.pro2-parabox .pro2-spec { padding: 42px 0 70px; }

@media screen and (max-width: 999px) { .pro2-parabox .pro2-spec { padding: 42px 0 50px; } }

@media screen and (max-width: 767px) { .pro2-parabox .pro2-spec { padding: 30px 0; } }

/* pro2-parabox end */
/* related-pro start */
.related-pro .pro1-list { padding-bottom: 0; }

.related-pro .pro1-list .list-item { margin-top: 45px; margin-bottom: 0; }

@media screen and (max-width: 767px) { .related-pro .pro1-list .list-item { margin-top: 30px; } }

.related-pro .pro1-list .item-btnbox { text-align: center; padding-left: 0; }

/* related-pro end */
/*-------------products2 end---------------------------------------------------------------*/
.downbtn{display: block;width: 430px;height: 46px;line-height: 46px;text-align: center;color: #fff;font-size:16px;font-size:1.6rem;margin-top: 35px;background: #5db161;margin-bottom: 35px;}
.downbtn i{color: #fff;margin-right: 15px;font-size:18px;font-size:1.8rem;}

.cpxx { line-height: 30px;padding-top: 12px;border-top: 4px solid #f9f9f9;margin-top: 20px;}

.cpxx a { color: #5db161; }

.pro2-info { padding-top: 21px; width: 600px; }

.share { margin-top: 6px; }

.share1 { color: #555555; margin-right: 5px; line-height: 26px; }

.share img { vertical-align: middle; }

.btn { margin-top: 59px; }

.btn a { display: inline-block; min-width: 127px; height: 42px; line-height: 42px; box-sizing: border-box;padding: 0 10px;text-align: center;background: #fff; color: #333333; font-size: 16px; font-size: 1.6rem; border: 1px solid #5db161;}

.btn a:hover { background: #5db161; color: #fff;}

.btn1 { margin-right: 11px; }

.des{font-size:20px;font-size:2rem;font-weight: bold;padding-bottom: 20px;background: url(../images/desbg.jpg) no-repeat left bottom;margin-bottom: 26px;margin-top: 28px;}

.chanpinx { line-height: 30px; padding-top: 3px; color: #555555; }

.chanpinx li, .chanpinx span, .chanpinx b, .chanpinx p { color: #555555; }

.chanpinx a { color: #5db161; }

.chanpinx img { max-width: 100%; }

.chanpinx iframe { max-width: 100%; }

.chanpinx table, .nex table, .cpxx table { width: 97%; border-collapse: collapse; line-height: 34px; text-align: center; font-size:16px;font-size:1.6rem;}

.chanpinx tr td, .nex tr td, .cpxx tr td { line-height: 34px; padding: 2px; }

.table table { width: 100%; border-collapse: collapse; line-height: 34px;margin-top: 7px; }

.table table tr td { padding: 0 11px;border-top: 1px solid #dedede;border-bottom: 1px solid #dedede;color: #888;}
.table table tr td:first-child{background: #ebf4f6;}


@media screen and (max-width: 1000px) { .table { width: 100%; overflow: scroll; }
  .table table { width: 885px; } }

.des2{margin-bottom: 47px;}

.zjpbox { width: 204px; float: left; margin-left: 45px; }

.zjpbox img { display: block; width: 100%; max-width: 425px; max-height: 425px; }

.zjptu{display: block;border: 1px solid #dbd6d6;}

.zjpbox p { text-align: center; margin-top: 6px; }

.zjpbox p a { display: block;line-height: 32px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.zjpbox p a:hover { color: #5db161; }

.tags2{padding-top: 31px;border-top: 4px solid #f7f6f6;margin-top: 26px;}
.tags2 a{display: inline-block;line-height: 32px;padding: 0 39px;border: 1px solid #4caf50;font-size:16px;font-size:1.6rem;color: #4caf50;margin-right: 15px;margin-bottom: 5px;}
.tags2 a:hover{background: #4caf50;color: #fff;}

@media screen and (max-width: 1219px) { .btn1 { margin-right: 5px; }
  .pro2-info { width: 100%; padding-top: 30px; }
  .zjpbox { margin-left: 15px; width: 184px; } }

@media screen and (max-width: 999px) { .share { display: inline-block; }
  .zjpbox { width: 18%; margin: 0 1%; } 
  .downbtn{margin: 0 auto;margin-top: 25px;}
}
@media screen and (max-width: 767px) { 
  .btn { display: block; margin-bottom: 15px; margin-top: 30px;}
  .zjpbox { width: 31.3333%; margin-bottom: 20px; }
 }
@media screen and (max-width: 500px) {
  .btn a { width: 135px; }
  .pro2-info .item-title { font-size: 16px; font-size: 1.6rem; } 
  .des{font-size:18px;font-size:1.8rem;margin-bottom: 15px;}
  .zjpbox{width: 48%;}
  .downbtn{width: 100%;}
  .tags2 a{padding: 0 20px;font-size:14px;font-size:1.4rem;}
}

/***********  application1  ************/
.application1{padding-top: 120px;}
.apphang{background: #f9f9f9;position: relative;margin-bottom: 88px;}
.apptu{position: absolute;bottom: 24px;display: block;border: 5px solid #4caf50;width: 528px;}
.apphang1 .apptu{left: 40px;}
.apphang2 .apptu{right: 40px;}
.apptu img{display: block;width: 100%;max-width: 949px;max-height: 600px;}
.appnr{padding-top: 45px;padding-bottom: 43px;}
.apphang1 .appnr{padding-left: 615px;padding-right: 45px;}
.apphang2 .appnr{padding-right: 615px;padding-left: 45px;}
.appp1{display: block;font-size:24px;font-size:2.4rem;font-weight: bold;text-transform: uppercase;}
.appp2{display: block;font-size:16px;font-size:1.6rem;color: #666666;line-height: 30px;height: 120px;overflow: hidden;margin-top: 13px;margin-bottom: 22px;}
.appp3{display: inline-block;min-width: 169px;height: 40px;line-height: 40px;text-align: center;color: #fff;background: #4caf50;font-size:16px;font-size:1.6rem;font-weight: bold;text-transform: uppercase;}
.appp3 i{font-size:18px;font-size:1.8rem;margin-left: 10px;}

.appp1:hover{color: #4caf50;}
.appp2:hover{color: #4caf50;}
.appp3:hover{background: #333;}

@media screen and (max-width:1219px) {
	.apptu{width: 430px;}
	.apphang1 .appnr{padding-left: 510px;}
	.appnr{padding-top: 30px;padding-bottom: 30px;}
	.appp1{font-size:20px;font-size:2rem;}
	.apphang{margin-bottom: 70px;}
	.application1{padding-top: 85px;}
}
@media screen and (max-width:999px) {
	.apptu{width: 345px;}
	.apphang1 .apptu{left: 22px;}
	.apphang2 .apptu{right: 22px;}
	.apphang1 .appnr{padding-left: 400px;padding-right: 20px;}
	.apphang2 .appnr{padding-right: 400px;padding-left: 20px;}
	.appp2{font-size:14px;font-size:1.4rem;}
	.appp3{font-size:14px;font-size:1.4rem;min-width: 150px;}
	.application1{padding-top: 50px;}
	.apphang{margin-bottom: 30px;}
}
@media screen and (max-width:767px) {
	.apptu{border: 2px solid #4caf50;position: static;width: auto;max-width: 70%;margin: 0 auto;}
	.apphang .appnr{padding: 20px;}
}
@media screen and (max-width:500px) {
	.apptu{max-width: 100%;}
	.appp1{font-size:18px;font-size:1.8rem;}
}

/***********  application2  ************/
.xwxbt { font-size: 24px; font-size: 2.4rem; color: #333333; line-height: 30px; font-weight: bold; text-align: center;padding-bottom: 22px;}

.xwxbt2{padding-bottom: 2px;}

.xwsjsjj { line-height: 26px;text-align: center;margin-bottom: 7px;}

.xwsjsjj span { display: inline-block; color: #666666; padding-right: 6px;}

.nshare { display: inline-block; }

.nshare1 { color: #555555; display: inline-block; position: relative; padding-right: 5px;}

.nshare2 { display: inline-block; vertical-align: middle; }

.nex { color: #333333; line-height: 30px; padding-top: 35px; padding-bottom: 40px; border-bottom: 5px solid #f7f6f6; border-top:5px solid #f7f6f6;margin-bottom: 22px;}

.nex p, .nex li, .nex div, .nex span, .nex b { color: #333333; }

.nex a { color: #4caf50; }

.xwsx { color: #333333; line-height: 30px;font-size:16px;font-size:1.6rem;}

.xwsx span { color: #333333; display: inline-block; margin-right: 10px; }

.xwsx a { color: #333; font-size: 16px; font-size: 1.6rem; }

.xwsx a:hover { color: #4caf50; }

@media screen and (max-width: 767px) { .xwxbt { font-size: 18px; font-size: 1.8rem; }
  .nex { padding-top: 25px; } 
  .xwsx{font-size:14px;font-size:1.4rem;}
}

/**************  download  *************/
.downbox { position: relative; padding-bottom: 50px; border-bottom: 5px solid #f7f6f6; margin-bottom: 38px; }

.dnr { padding-right: 290px; }

.da1 { display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 18px; font-size: 1.8rem; font-weight: bold;color: #1f1e19;line-height: 30px; cursor: pointer;}

.da2 { display: block; line-height: 16px; margin-top: 5px; height: 32px; overflow: hidden;font-weight: bold; cursor: pointer;}

.dbtn { display: inline-block; min-width: 163px; height: 32px; line-height: 32px; text-align: center; background: #000000; position: absolute; right: 12px; bottom: 59px; color: #fff; font-size:16px;font-size:1.6rem;}

.dbtn i { font-size: 20px; font-size: 2rem; vertical-align: middle; margin-right: 2px; }

.da1:hover { color: #4caf50; }

.da2:hover { color: #4caf50; }

.dbtn:hover { background: #4caf50;}

.download .ms_ye { margin-top: 53px; }

@media screen and (max-width:1219px) {
	.dnr{padding-right: 220px;}
}
@media screen and (max-width: 767px) { .dnr { padding-right: 0; }
  .downbox { padding-bottom: 75px; margin-bottom: 22px; }
  .dbtn { bottom: 15px; }
  .da1{font-size:16px;font-size:1.6rem;}
}

/**********  news1  **********/
.nbox { width: 384px; float: left; margin-left: 24px; border-radius: 5px; overflow: hidden; margin-bottom: 20px;box-shadow:  0px 0px 10px 0px rgba(0,0,0,0);transition: 0.5s;}

.ntu img { display: block; width: 100%; max-width: 397px; max-height: 300px; }

.nxia { background: #fff; padding: 0 25px; padding-top: 29px; padding-bottom: 37px; border: 1px solid #dbd6d6;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;}

.nxia a { display: block; font-size: 18px; font-size: 1.8rem; line-height: 24px;font-weight: bold;height: 48px; overflow: hidden; margin-bottom: 20px; }

.nxia span { display: block; font-size: 16px; font-size: 1.6rem; color: #666666; }

.nxia span img { vertical-align: middle; margin: 0 1px; }

.nxia a:hover { color: #4caf50; }

.nbox:hover{box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);}

@media screen and (max-width:1219px) {
	.nbox{width: 310px;}
}
@media screen and (max-width:999px) {
	.nbox{width: 31.333%;margin: 0 1%;margin-bottom: 30px;}
	.nxia a{font-size:16px;font-size:1.6rem;}
	.nxia{padding-left: 15px;padding-right: 15px;}
}
@media screen and (max-width:650px) {
	.nbox{width: 48%;margin-bottom: 20px;}
}
@media screen and (max-width:450px) {
	.nbox{width: auto;float: none;max-width: 397px;margin: 0 auto;margin-bottom: 15px;}
}

/*************  contact  *************/
.lxlist{background: #f9f9f9;padding: 0 87px;padding-top: 52px;padding-bottom: 18px;}
.lxlist li{font-size:24px;font-size:2.4rem;color: #1f1e19;line-height: 30px;margin-bottom: 27px;}
.lxlist li a{color: #1f1e19;}
.lxlist li a:hover{color: #4caf50;}

@media screen and (max-width:999px) {
	.lxlist li{font-size:20px;font-size:2rem;margin-bottom: 15px;}
	.lxlist{padding-left: 50px;padding-right: 50px;}
}
@media screen and (max-width:767px) {
	.lxlist li{font-size:16px;font-size:1.6rem;margin-bottom: 10px;}
	.lxlist{padding-top: 30px;padding-left: 30px;padding-right: 30px;}
}

/***********  xiala  ***********/
.yiji{position: absolute;z-index: 9999;width: 130px;background: url(../images/yijibg.png);top: -9999px;left: 0;padding-bottom: 29px;padding-top: 12px;}
.yiji li{position: relative;}
.yiji li a{display: block;color: #333;line-height: 24px;padding-top: 5px;padding-bottom: 5px;padding-left: 30px;padding-right: 10px;color: #fff;}
.yiji li:hover>a{background:#4caf50;}

.erji,.sanji{position: absolute;left: 100%;top: -999px;padding-left: 10px;}
.erji ul{width: 130px;background: url(../images/yijibg.png);padding-bottom: 29px;padding-top: 12px;}
.navlist>li:hover .yiji{top: 100%;}
.yiji>li:hover .erji{top: -12px;}
.erji li:hover .sanji{top: -12px;}

@media screen and (min-width:1000px) and (max-width:1219px) {

}
@media screen and (max-width:999px) {
    .yiji{display: none;}
}


.hdtop{position: fixed;right: 20px;bottom: -100px;transition: 1s;cursor: pointer;z-index: 99999;}

.preloader {
	background: #000;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 99999;
}
.la-ball-circus, .la-ball-circus>div {
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.la-ball-circus {
	display: block;
	font-size: 0;
	color: #fff
}
.la-ball-circus.la-dark {
	color: #FFF
}
.la-ball-circus>div {
	display: inline-block;
	float: none;
	background-color: currentColor;
	border: 0 solid currentColor
}
.la-ball-circus {
	width: 16px;
	height: 16px
}
.la-ball-circus>div {
	position: absolute;
	top: 0;
	left: -100%;
	display: block;
	width: 16px;
	width: 100%;
	height: 16px;
	height: 100%;
	border-radius: 100%;
	opacity: .5;
	-webkit-animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1), ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1);
	-moz-animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1), ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1);
	-o-animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1), ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1);
	animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1), ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1)
}
.la-ball-circus>div:nth-child(1) {
	-webkit-animation-delay: 0s, -0.5s;
	-moz-animation-delay: 0s, -0.5s;
	-o-animation-delay: 0s, -0.5s;
	animation-delay: 0s, -0.5s
}
.la-ball-circus>div:nth-child(2) {
	-webkit-animation-delay: -0.5s, -1s;
	-moz-animation-delay: -0.5s, -1s;
	-o-animation-delay: -0.5s, -1s;
	animation-delay: -0.5s, -1s
}
.la-ball-circus>div:nth-child(3) {
	-webkit-animation-delay: -1s, -1.5s;
	-moz-animation-delay: -1s, -1.5s;
	-o-animation-delay: -1s, -1.5s;
	animation-delay: -1s, -1.5s
}
.la-ball-circus>div:nth-child(4) {
	-webkit-animation-delay: -1.5s, -2s;
	-moz-animation-delay: -1.5s, -2s;
	-o-animation-delay: -1.5s, -2s;
	animation-delay: -1.5s, -2s
}
.la-ball-circus>div:nth-child(5) {
	-webkit-animation-delay: -2s, -2.5s;
	-moz-animation-delay: -2s, -2.5s;
	-o-animation-delay: -2s, -2.5s;
	animation-delay: -2s, -2.5s
}
.la-ball-circus.la-sm {
	width: 8px;
	height: 8px
}
.la-ball-circus.la-sm>div {
	width: 8px;
	height: 8px
}
.la-ball-circus.la-2x {
	width: 32px;
	height: 32px;
	left: 0;
	top: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	position: absolute;
}
.la-ball-circus.la-2x>div {
	width: 32px;
	height: 32px
}
.la-ball-circus.la-3x {
	width: 48px;
	height: 48px
}
.la-ball-circus.la-3x>div {
	width: 48px;
	height: 48px
}
@-webkit-keyframes ball-circus-position {
50% {
left:100%
}
}
@-moz-keyframes ball-circus-position {
50% {
left:100%
}
}
@-o-keyframes ball-circus-position {
50% {
left:100%
}
}
@keyframes ball-circus-position {
50% {
left:100%
}
}
@-webkit-keyframes ball-circus-size {
50% {
-webkit-transform:scale(0.3, 0.3);
transform:scale(0.3, 0.3)
}
}
@-moz-keyframes ball-circus-size {
50% {
-moz-transform:scale(0.3, 0.3);
transform:scale(0.3, 0.3)
}
}
@-o-keyframes ball-circus-size {
50% {
-o-transform:scale(0.3, 0.3);
transform:scale(0.3, 0.3)
}
}
@keyframes ball-circus-size {
50% {
-webkit-transform:scale(0.3, 0.3);
-moz-transform:scale(0.3, 0.3);
-o-transform:scale(0.3, 0.3);
transform:scale(0.3, 0.3)
}
}
.site-welcome {
	display: none;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 99999;
}
.site-welcome.active {
	display: flex;
}




/*********************fangdaxiaoguo**********************/
#baguetteBox-overlay { display: none; opacity: 0; position: fixed; overflow: hidden; top: 0; left: 0; width: 100%; height: 100%; background-color: #222; background-color: rgba(0, 0, 0, 0.8); -webkit-transition: opacity .5s ease; -o-transition: opacity .5s ease; -moz-transition: opacity .5s ease; transition: opacity .5s ease; z-index: 99999999999999999999999999999999999999; }

#baguetteBox-overlay.visible { opacity: 1; }

#baguetteBox-overlay .full-image { display: inline-block; position: relative; width: 100%; height: 100%; text-align: center; }

#baguetteBox-overlay .full-image figure { display: inline; margin: 0; height: 100%; }

#baguetteBox-overlay .full-image img { display: inline-block; width: auto; height: auto; max-height: 100%; max-width: 100%; vertical-align: middle; -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }

#baguetteBox-overlay .full-image figcaption { display: block; position: absolute; bottom: 0; width: 100%; text-align: center; line-height: 1.8; color: #ccc; background-color: #000; background-color: rgba(0, 0, 0, 0.6); font-family: Verdana, Geneva, sans-serif; }

#baguetteBox-overlay .full-image:before { content: ""; display: inline-block; height: 50%; width: 1px; margin-right: -1px; }

#baguetteBox-slider { position: absolute; left: 0; top: 0; height: 100%; width: 100%; white-space: nowrap; -webkit-transition: left .4s ease,-webkit-transform .4s ease; transition: left .4s ease,-moz-transform .4s ease; transition: left .4s ease,-webkit-transform .4s ease; -o-transition: left .4s ease,-o-transform .4s ease; -moz-transition: left .4s ease,transform .4s ease,-moz-transform .4s ease; transition: left .4s ease,transform .4s ease; transition: left .4s ease,transform .4s ease,-webkit-transform .4s ease,-moz-transform .4s ease,-o-transform .4s ease; }

#baguetteBox-slider.bounce-from-right { -webkit-animation: bounceFromRight .4s ease-out; -moz-animation: bounceFromRight .4s ease-out; animation: bounceFromRight .4s ease-out; }

#baguetteBox-slider.bounce-from-left { -webkit-animation: bounceFromLeft .4s ease-out; -moz-animation: bounceFromLeft .4s ease-out; animation: bounceFromLeft .4s ease-out; }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button { top: 50%; top: -webkit-calc(50% - 30px); top: -moz-calc(50% - 30px); top: calc(50% - 30px); width: 44px; height: 60px; }

.baguetteBox-button { position: absolute; cursor: pointer; outline: 0; padding: 0; margin: 0; border: 0; border-radius: 15%; background-color: #323232; background-color: rgba(50, 50, 50, 0.5); color: #ddd; font: 1.6em sans-serif; -webkit-transition: background-color .4s ease; -o-transition: background-color .4s ease; -moz-transition: background-color .4s ease; transition: background-color .4s ease; }

.baguetteBox-button:hover { background-color: rgba(50, 50, 50, 0.9); }

.baguetteBox-button#next-button { right: 2%; }

.baguetteBox-button#previous-button { left: 2%; }

.baguetteBox-button#close-button { top: 20px; right: 2%; right: -webkit-calc(2% + 6px); right: -moz-calc(2% + 6px); right: calc(2% + 6px); width: 30px; height: 30px; }

.baguetteBox-button svg { position: absolute; left: 0; top: 0; }

.spinner { width: 40px; height: 40px; display: inline-block; position: absolute; top: 50%; left: 50%; margin-top: -20px; margin-left: -20px; }

.double-bounce1, .double-bounce2 { width: 100%; height: 100%; border-radius: 50%; background-color: #fff; opacity: .6; position: absolute; top: 0; left: 0; -webkit-animation: bounce 2s infinite ease-in-out; -moz-animation: bounce 2s infinite ease-in-out; animation: bounce 2s infinite ease-in-out; }

.double-bounce2 { -webkit-animation-delay: -1s; -moz-animation-delay: -1s; animation-delay: -1s; }

@-webkit-keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@-moz-keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@-webkit-keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@-moz-keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@-webkit-keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1); } }

@keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }


@font-face {
	font-family: "AMAZB";
	src: url("../fonts/AMAZB.woff2") format("woff2"),
			url("../fonts/AMAZB.woff") format("woff"),
			url("../fonts/AMAZB.ttf") format("truetype"),
			url("../fonts/AMAZB.eot") format("embedded-opentype"),
			url("../fonts/AMAZB.svg") format("svg");
	font-weight: normal;
	font-style: normal;
	}


